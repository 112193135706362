<template>
  <div>
    <b-card>
      <b-button-toolbar>
        <b-button-group>
          <b-button
            variant="primary"
            @click="onCreateUser"
          >
            Create user
          </b-button>
          <b-button
            v-b-modal.tagsModal
            variant="secondary"
          >
            Assign tags
          </b-button>
          <b-button
            v-b-modal.deleteMultipleModal
            variant="info"
          >
            Delete users
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card>
    <user-modal />
    <b-modal
      id="tagsModal"
      title="Assign tags to users"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <multiple-tag-form
        items-name="Users email"
        :is-busy="tagsToUserLoading"
        show-purchase-disabled-option
        @submit="onSubmitMultipleTags"
      />
    </b-modal>
    <b-modal
      id="deleteMultipleModal"
      title="Delete users by email (separated by ',')"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <text-collection-form
        items-name="Users email"
        :is-busy="userDeleteLoading"
        @submit="onSubmitDeleteMultiple"
      />
    </b-modal>
    <gql-table
      ref="usersTable"
      :fields="fields"
      :query="query"
      query-type="users"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="userActionsList"
      v-on="userHandlers"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(full_name)="data">
        <b-link :to="{name: 'user-detail', params: {id: data.item.id}}">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </b-link>
      </template>

      <template #cell(email)="data">
        {{ data.item.email }}
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="getExtraTags(data.item)"
          extras-variant="light-success"
        />
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

    </gql-table>
  </div>
</template>

<script>
import {
  BButton,
  BLink,
  BButtonToolbar,
  BButtonGroup,
  BCard,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import RowDetail from '@/components/page/users/components/RowDetail.vue'
import { formatDateTime } from '@/plugins/formaters'
import UserAPI from '@/api/user'
import TagsList from '@/components/page/TagsList.vue'
import MultipleTagForm from '@/components/forms/tag/MutipleTagForm.vue'
import TextCollectionForm from '@/components/forms/TextCollectionForm.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import userActions from '@/mixins/userActions'

const fields = [
  'show_details',
  {
    name: 'shortId',
    label: 'MT ID',
    filterable: 'eq',
    visible: false,
  },
  {
    name: 'full_name',
    label: 'Full name',
  },
  {
    name: 'firstName', label: 'First name', filterable: 'like', visible: false,
  },
  {
    name: 'lastName', label: 'Last name', filterable: 'like', visible: false,
  },
  {
    name: 'email',
    label: 'Email',
    filterable: 'like',
    sortable: true,
  },
  {
    name: 'tags',
    label: 'Tag',
    filterable: {
      type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    sortable: true,
    filterable: 'daterange',
  },
  {
    name: 'kyc_at',
    label: 'KYC at',
    filterable: 'daterange',
    visible: false,
  },
  {
    name: 'country',
    label: 'Country',
    filterable: {
      type: 'multientity', queryType: 'countries', value: 'id', label: 'name', search: 'name',
    },
    visible: false,
  },
  {
    name: 'address',
    label: 'Address',
    filterable: 'like',
    visible: false,
  },
  {
    name: 'affKey', label: 'Registered under affiliate key', filterable: 'like', visible: false,
  },
  {
    name: 'isVerified',
    label: 'Is verified',
    filterable: { type: 'is' },
    visible: false,
  },
  {
    name: 'actions',
  },
]

const query = [
  'firstName',
  'lastName',
  'id',
  'email',
  'kyc_at',
  'purchase_disabled',
  'profile_image',
  'createdAt',
  'phone',
  'address',
  'city',
  'zipcode',
  'state',
  { country: { fields: ['name', 'code2'] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
]

export default {
  components: {
    TextCollectionForm,
    ShowDetails,
    RowDetail,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BLink,
    BCard,
    TagsList,
    MultipleTagForm,
    GqlTable,
  },
  mixins: [userActions],
  data() {
    return {
      fields,
      query,
      tagsToUserLoading: false,
      userDeleteLoading: false,
    }
  },
  methods: {
    formatDateTime,
    getExtraTags(item) {
      const tags = []
      if (item.kyc_at && item.verification_data !== null) tags.push(`Verified ${formatDateTime(item.kyc_at)}`)
      if (item.kyc_at && item.verification_data === null) tags.push(`Verified by support ${formatDateTime(item.kyc_at)}`)
      if (item.purchase_disabled) tags.push({ name: 'Purchase disabled', variant: 'light-danger' })
      return tags
    },
    onSubmitMultipleTags(data) {
      this.tagsToUserLoading = true
      UserAPI.postTagsToUsers(data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tags was assigned to users',
              icon: 'SendIcon',
              text: 'Tags was successfully assigned to users.',
              variant: 'success',
            },
          })
          this.$bvModal.hide('tagsModal')
        })
        .catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some tags was not assign to users',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.usersTable.reloadAll(true)
          this.tagsToUserLoading = false
        })
    },
    onSubmitDeleteMultiple(items) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Users will be permanently deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed) {
          this.userDeleteLoading = true
          UserAPI.deleteUsers(items)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'All users was removed.',
                  icon: 'SendIcon',
                  text: 'All users was removed.',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('deleteMultipleModal')
            })
            .catch(error => {
              if (error.status === 400) {
                Swal.fire({
                  title: 'This users can not be removed: ',
                  text: error.data.detail,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .finally(() => {
              this.$refs.usersTable.reloadAll(true)
              this.userDeleteLoading = false
            })
        }
      })
    },
    reload() {
      this.$refs.usersTable.reloadAll(true)
    },
  },
}
</script>
